<template>
  <v-container fluid class="pa-0">
    <v-row justify="center" class="mt-5 py-5">
      <v-col cols="10">
        <h1
          class="text-center font-weight-bold"
          :class="$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline'"
        >
          POLÍTICA DEL USO DE COOKIES
        </h1>
      </v-col>
    </v-row>

    <v-row class="mb-10">
      <!-- INTRODUCCIÓN -->
      <v-col cols="10" offset="1" md="8" offset-md="2" class="text-justify">
        <p>
          Este aviso es emitido por&nbsp;
          <strong>PLATAFORMA DIGITAL WE CHAMBER S.A.P.I. de C.V.</strong>
          &nbsp;sus filiales y/o subsidiarias, y/o sus partes
          relacionadas&nbsp;(&ldquo;We Chamber&rdquo;), con domicilio en Blvd.
          Campestre 403 Piso 1, Col. Jardines del Moral C.P. 37160 en
          Le&oacute;n, Guanajuato, direcci&oacute;n electr&oacute;nica:&nbsp;<a
            href="http://www.wechamber.mx"
            >www.wechamber.mx</a
          >
          (el &ldquo;Sitio&rdquo;) y con correo electr&oacute;nico de
          contacto&nbsp;<a href="mailto:contacto@wechamber.mx"
            >contacto@wechamber.mx</a
          >
          (el &ldquo;Correo Electr&oacute;nico&rdquo;), con la finalidad de dar
          a conocer a cualquier persona que acceda o haga uso del Sitio (el
          &ldquo;Usuario&rdquo;), el uso de cookies que hace We Chamber en el
          mismo.
        </p>
        <p>&nbsp;</p>
        <p>
          Las partes entender&aacute;n como cookies, aquellos archivos cortos
          que habitualmente consisten en letras y n&uacute;meros, que se colocan
          en la computadora, tablet, tel&eacute;fono o dispositivo similar que
          el Usuario utilice para acceder al Sitio (&ldquo;cookies&rdquo;) y/o
          aquellas tecnolog&iacute;as similares, como etiquetas de p&iacute;xel,
          balizas web, GIF transparentes, JavaScript y almacenamiento local
          (&ldquo;balizas web&rdquo;), que puedan estar relacionadas con el
          Sitio.
        </p>
        <p>&nbsp;</p>
        <p>
          El acceso y/o uso del Sitio&nbsp;implica la aceptaci&oacute;n plena de
          los t&eacute;rminos aqu&iacute; contenidos. En consecuencia, es
          responsabilidad &uacute;nica y exclusiva del Usuario, leer previamente
          esta pol&iacute;tica de uso y sus modificaciones correspondientes,
          cada vez que accede al Sitio. Si en cualquier momento, el Usuario no
          estuviera de acuerdo total o parcialmente con los presentes
          t&eacute;rminos, deber&aacute;, a su criterio, deshabilitar las
          cookies establecidas o abstenerse inmediatamente de usar el Sitio.
        </p>
        <p>&nbsp;</p>
        <p class="title font-weight-bold">OBJETO.&nbsp;</p>
        <p>
          Con la finalidad de proporcionar al Usuario la mejor funcionalidad
          posible del Sitio e informaci&oacute;n personalizada adecuada a sus
          intereses, We Chamber sus socios y/o aliados, podr&aacute;n hacer uso
          de cookies, para recopilar datos que permitan brindar
          informaci&oacute;n anal&iacute;tica, as&iacute; como la
          identificaci&oacute;n personal, o que podr&iacute;a permitir la
          identificaci&oacute;n personal bajo la combinaci&oacute;n con otra
          informaci&oacute;n, en cuyo caso, se considerar&aacute; regido bajo
          los t&eacute;rminos establecidos en el Aviso de Privacidad de We
          Chamber y en el presente documento.&nbsp;
        </p>
        <p>&nbsp;</p>
        <p>
          We Chamber, como operador del Sitio, puede poner cookies en el
          dispositivo por el cual el Usuario accede a este. Estas cookies se
          llaman cookies &quot;de origen&quot;. Las cookies dispuestas por
          proveedores que presten servicios a trav&eacute;s del Sitio, tales
          como correo electr&oacute;nico, se les llama &ldquo;de
          terceros&rdquo;. We Chamber no controla el uso de esta
          tecnolog&iacute;a, o la informaci&oacute;n resultante, y no es
          responsable por ninguna acci&oacute;n o pol&iacute;tica de esas
          terceras partes.
        </p>
        <p>&nbsp;</p>
        <p>
          We Chamber hace del conocimiento del Usuario que, por medio de las
          cookies, es posible obtener y utilizar informaci&oacute;n agregada y
          de otro tipo que NO IDENTIFICA a los Usuarios individuales (como su
          sistema operativo, su versi&oacute;n de navegador y la URL de origen,
          incluidos mensajes de correo electr&oacute;nico y anuncios
          publicitarios) para mejorar su experiencia y comprender los patrones
          de tr&aacute;fico. Esta tecnolog&iacute;a se utiliza como una
          herramienta para compilar estad&iacute;sticas agregadas acerca del uso
          del Sitio para an&aacute;lisis y desempe&ntilde;o y
          optimizaci&oacute;n del mismo.
        </p>
        <p>&nbsp;</p>
        <p>
          We Chamber manifiesta que puede tambi&eacute;n hacer uso de
          tecnolog&iacute;as similares, como las balizas web (a veces llamadas
          &quot;p&iacute;xeles de seguimiento&quot; o &quot;GIF
          transparentes&quot;). Estos son archivos gr&aacute;ficos, muy
          peque&ntilde;os, contienen un identificador &uacute;nico que permite
          reconocer la visita al Sitio o notificar a We Chamber cuando el
          Usuario abra un correo electr&oacute;nico que haya sido enviado por We
          Chamber. Generalmente, estas tecnolog&iacute;as se sustentan en
          cookies para que funcionen de manera correcta, por lo que su
          disminuci&oacute;n afecta directamente su funcionamiento.
        </p>
        <p>&nbsp;</p>
        <p class="title font-weight-bold">TIPO DE COOKIES&nbsp;</p>
        <p>
          We Chamber y sus proveedores de servicios podr&aacute;n hacer uso de
          uno o m&aacute;s tipos de cookies, en adelante descritas:&nbsp;
        </p>
        <ul>
          <li>
            <p>
              <strong>Cookies esenciales.</strong> &nbsp;Requeridas para mejorar
              el funcionamiento del Sitio, permitiendo al Usuario recorrer el
              Sitio y hacer uso de sus caracter&iacute;sticas (por ejemplo, para
              permitirle al Usuario iniciar sesi&oacute;n en &aacute;reas
              seguras del Sitio o utilizar un carro de compras). La
              deshabilitaci&oacute;n de estas cookies provocar&aacute; un
              entorpecimiento del desempe&ntilde;o del Sitio, y posiblemente
              dejar&aacute; sin disponibilidad las caracter&iacute;sticas del
              Sitio y su servicio.
            </p>
          </li>
          <li>
            <p>
              <strong>
                Cookies de an&aacute;lisis y personalizaci&oacute;n.
              </strong>
              &nbsp;Dise&ntilde;adas para analizar la actividad del Usuario en
              el Sitio y/o en los sitios donde We Chamber publique o promocione
              contenidos, mismas que se usan para asegurar que el Usuario pueda
              encontrar la informaci&oacute;n que busca en el Sitio de una
              manera m&aacute;s f&aacute;cil. Estas cookies permiten el
              reconocimiento y contabilizaci&oacute;n de visitantes al Sitio,
              as&iacute; como la medici&oacute;n del desempe&ntilde;o de
              campa&ntilde;as publicitarias y optimizaci&oacute;n &nbsp;del
              contenido del Sitio por parte de We Chamber.
            </p>
          </li>
          <li>
            <p>
              <strong>Cookies de funcionalidad.</strong> Utilizadas para
              reconocer al Usuario cuando ingresa o regresa al Sitio de We
              Chamber, mediante la personalizaci&oacute;n del Contenido, uso del
              nombre registrado como Usuario &nbsp;y preferencias tales como:
              idioma, regi&oacute;n, etc.
            </p>
          </li>
          <li>
            <p>
              <strong>Cookies de publicidad.</strong> &nbsp;Estas cookies son
              utilizadas con el objeto de resaltar el contenido del Sitio que
              sea m&aacute;s relevante para el Usuario, en funci&oacute;n de los
              intereses aparentes recopilados seg&uacute;n su actividad en
              l&iacute;nea, incluidas las visitas al Sitio, las p&aacute;ginas
              que ha visitado y la selecci&oacute;n de enlaces y anuncios. Lo
              anterior permite que, mediante el uso de plataformas de
              administraci&oacute;n y edici&oacute;n de datos, se enlacen avisos
              publicitarios en el Sitio propiedad de We Chamber o en cualquier
              otro, con la finalidad de mantener una promoci&oacute;n continua
              de los productos y servicios visitados en el Sitio.&nbsp;
            </p>
          </li>
        </ul>

        <br />
        <p class="title font-weight-bold">DEL USO DE COOKIES.</p>

        <br />
        <p>
          El uso de cookies permite a We Chamber, y a sus proveedores de
          servicio, recabar ciertos datos sensibles del Usuario a fin de: i)
          obtener informaci&oacute;n acerca de sus visitas al Sitio; ii)
          procesar los pedidos del Usuario; iii) analizar sus patrones de visita
          para mejoras del Sitio; iv) ofrecer publicidad, comunicaciones y
          contenidos de We Chamber y de terceros aliados en el Sitio y en el de
          terceros que sean espec&iacute;ficos en relaci&oacute;n con sus
          intereses; v) recordar el idioma y otras preferencias del Usuario; vi)
          ayudar al Usuario a obtener la informaci&oacute;n que busca; vii)
          ofrecer un servicio seguro para las transacciones en l&iacute;nea;
          viii) cuantificar el acceso y uso del Sitio; ix) ofrecer una
          funcionalidad eficaz y en concordancia a la audiencia del Sitio, entre
          otros.
        </p>
        <p>
          El registro de &nbsp;informaci&oacute;n personal por parte del Usuario
          en el Sitio, permite a We Chamber que, por medio de la
          asociaci&oacute;n de dicha informaci&oacute;n con otra
          informaci&oacute;n que se recabe a trav&eacute;s de terceros que se
          encuentre directa o indirectamente relacionada con el uso del Sitio,
          se use la misma con fines de an&aacute;lisis y de mercadeo, siempre
          estad&iacute;stica y no individualizada.
        </p>

        <br />
        <p class="title font-weight-bold">
          TIPOS DE COOKIES RESPECTO A SUS PERMANENCIA EN EL SISTEMA.
        </p>

        <br />
        <p>
          La funci&oacute;n y permanencia de las cookies en el sistema del
          dispositivo a trav&eacute;s del cual se acceda al Sitio, pueden ser:
        </p>
        <ol>
          <li>
            <p>
              &quot;De sesi&oacute;n&quot;, las cuales se activan en el momento
              de su acceso al Sitio y hasta la finalizaci&oacute;n de esa
              sesi&oacute;n. Estas cookies vencen y se eliminan
              autom&aacute;ticamente cuando se cierra el navegador de Internet;
              o&nbsp;
            </p>
          </li>
          <li>
            <p>
              &ldquo;Persistentes&rdquo; las cuales permanecen en el dispositivo
              de acceso al Sitio entre sesiones de navegaci&oacute;n. La
              cantidad de tiempo que una cookie persistente permanece en su
              dispositivo var&iacute;a de una cookie a otra.
            </p>
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          El prop&oacute;sito para el cual We Chamber hace uso de cookies es: i)
          para almacenamiento de preferencias del Usuario, con la finalidad de
          mantenerlas disponibles para su pr&oacute;xima visita; ii) para
          mantener informaci&oacute;n de frecuencia y variaci&oacute;n en tiempo
          de visita al Sitio; y iii) para probar la eficacia de los esfuerzos de
          publicidad.
        </p>
        <p>&nbsp;</p>
        <p class="title font-weight-bold">
          ADMINISTRACI&Oacute;N DE COOKIES POR EL USUARIO.
        </p>
        <p>
          El Usuario, a trav&eacute;s de la configuraci&oacute;n de su navegador
          de Internet, podr&aacute; decidir si desea o no aceptar cookies del
          Sitio. We Chamber manifiesta que, el bloqueo por parte del Usuario de
          todas las cookies, puede provocar el bloqueo o afectaci&oacute;n total
          o parcial en el acceso y uso del Sitio.
        </p>
        <p>&nbsp;</p>
        <p>
          We Chamber se compromete a hacer buen uso de los datos personales y
          datos sensibles provistos por el Usuario, y que lo har&aacute;
          conforme al Aviso de Privacidad que se encuentra en el Sitio.
          Cualquier duda o comentario respecto al uso de cookies por parte de We
          Chamber, puede ser dirigida al Correo Electr&oacute;nico. En dicho
          caso, favor de incluir la informaci&oacute;n de contacto, el nombre
          del sitio web de We Chamber y una descripci&oacute;n detallada de la
          solicitud o preocupaci&oacute;n relacionada con las cookies.
        </p>
        <p><br /></p>
        <p class="title font-weight-bold">
          ACEPTACI&Oacute;N DE LOS T&Eacute;RMINOS.
        </p>
        <p>
          El&nbsp;Usuario&nbsp;reconoce que, mediante el acceso y uso del
          Sitio,&nbsp;manifiesta su aceptaci&oacute;n plena y sin reservas y,
          por tanto, su adhesi&oacute;n a la versi&oacute;n de estos
          t&eacute;rminos publicada en el momento en que acceda al Sitio, en
          t&eacute;rminos de lo establecido por los art&iacute;culos 1803 y 1834
          Bis del C&oacute;digo Civil Federal, 80, 81, 89 y dem&aacute;s
          relativos y aplicables del C&oacute;digo de Comercio y la
          legislaci&oacute;n aplicable para M&eacute;xico.&nbsp;Es
          responsabilidad &uacute;nica y exclusiva del Usuario, leer previamente
          estos t&eacute;rminos y sus modificaciones correspondientes, cada vez
          que accede al Sitio. Si, en cualquier momento, el Usuario no estuviera
          de acuerdo, total o parcialmente con los presentes t&eacute;rminos,
          deber&aacute; abstenerse inmediatamente de acceder y usar el
          Sitio.&nbsp;Por lo anterior, con la aceptaci&oacute;n de los presentes
          t&eacute;rminos, el Usuario consiente expresamente sujetarse a los
          mismos, por lo que manifiesta haber le&iacute;do el contenido de todas
          y cada una de las disposiciones y ratifica su contenido.
        </p>
        <p><br /></p>
        <p class="title font-weight-bold">ACUERDO TOTAL.</p>
        <p>
          El Usuario reconoce y acepta que We Chamber puso a su
          disposici&oacute;n toda la informaci&oacute;n para entender el alcance
          y caracter&iacute;sticas del Sitio. De igual forma manifiesta que,
          previo al acceso al Sitio, analiz&oacute; las caracter&iacute;sticas
          de &eacute;ste y, por consiguiente, est&aacute; de acuerdo con ellas.
        </p>
      </v-col>
      <v-col
        cols="10"
        offset="1"
        md="8"
        offset-md="2"
        class="text-center caption"
      >
        <p>
          Fecha de primera emisi&oacute;n: 22 de noviembre de 2022.Fecha de
          &uacute;ltima modificaci&oacute;n: 22 de noviembre de 2022.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style>
</style>